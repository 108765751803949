export class InterstitialConstants {
  public static readonly defaultflavorText = 'Get it now:'
  public static readonly toolTipProductImage = '/images/tooltips/big_product_image.png'
  public static readonly toolTipProductImageLogo = '/images/tooltips/big_product_logo.png'
  public static readonly toolTipProductImageTitle = '/images/tooltips/big_product_title.png'
  public static readonly toolTipProductImageCTA = '/images/tooltips/big_product_CTA.png'
  public static readonly maxDestinations = 150
  public static readonly addDestinationText = 'Add a destination choice'
  public static readonly addDestinationTextLimit = 'Limit ' + InterstitialConstants.maxDestinations
  public static readonly headerTooltip = 'Let your visitors choose from several stores after they click your link. Just add a product link for each store below.'
  public static readonly productImageTitle = 'Product image: '
  public static readonly productImageLightboxImage = 'image-3'
  public static readonly logoImageTitle = 'Page top logo: '
  public static readonly logoImageLightboxImage = 'image-4'
}
